import * as types from '@/store/mutation-types'
import i18n from '@/plugins/i18n'
import * as Validator from 'vee-validate'
import Vue from 'vue'
import Vuex from 'vuex'

const getters = {
  // type: (state) => state.type,
}

const actions = {
  timeCardAction({ commit }, payload) {
    // commit(types.SUCCESS, null)
    // commit(types.ERROR, null)

    commit(types.TIME_CARD, payload)
  },

}

const mutations = {
  [types.TIME_CARD](state, value) {
    console.log("VALUE ::: ", value)
    state.card = value
  },

}

const state = {
  type: null,
  action: null,
  drop: false,
  timer: 0,
  data: null,
  input: null,
  counter: 1,
  remote: null,
  targetID: null,
  targetShot: {},
  result: null,
  changeTarget: null,
  frame: null,
  cardSwitcher: null,
  cardSwitcherType: null,
  matterSwitcherType: null,
  matterSwitcher: null,
  move: "move",
  direction: "right",
  velocity: 50,
  followVelocity: 50,
  followDistance: 200,
  following: null,
  oneMatter: {},
  oneSensor: {},
  endDelay : 0,
  cardForgeData:null,
  currentTarget: null,
  config :{
    targets: [
      {
        target: null,
        targetNote: null,
        animations: [
          {
            animation: null,
            animationNote: null
          },
        ],
        selected: true,
      }
    ],
    rails:[],
    sensors:[],
    cards:[],
    sfxs:[
      {
        sfx: null,
        sfxNote: null,
      }
    ],
    extra:{
      targets:true,
      sensors:true,
      rails:false,
      cards:false,
      sfxs:false,
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
